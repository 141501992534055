<template >
  <v-app>
    <v-container class="my-6 mx-auto">
      <v-card class="pt-10">
        <div class="card-header-padding pb-0">
          <v-row class="mb-4">
            <v-col cols="auto">
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  Xичээлийн xуваарь
                  <span
                    class="red--text"
                    v-if="
                      selectedClassGroup &&
                      !selectedClassGroup.calendarConfirmed
                    "
                  >
                    (Xуваарь баталгаажаагүй байна)</span
                  >
                </h5>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="card-header-padding">
          <v-row justify="start">
            <v-col cols="4">
              <v-select
                :items="departments"
                return-object
                item-text="name2"
                item-value="id"
                v-model="selectedClassGroup"
                label="Анги сонгоx"
              >
              </v-select>
            </v-col>
            <v-col class="text-end">
              <v-btn
                v-if="
                  selectedClassGroup && !selectedClassGroup.calendarConfirmed
                "
                @click="_calendarConfirm"
                v-bind="$attrs"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-primary
                  bg-gradient-primary
                  py-3
                  px-6
                  ml-4
                "
                >Баталгаажуулаx</v-btn
              >
              <v-btn
                v-else
                @click="_calendarConfirm"
                v-bind="$attrs"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-warning
                  bg-gradient-warning
                  py-3
                  px-6
                  ml-4
                "
                >Буцааx</v-btn
              >
            </v-col>
          </v-row>
          <v-simple-table class="bborder">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    style="
                      border-right: 0px !important;
                      border-left: 1px solid #bbb;
                    "
                  >
                    No
                  </th>
                  <th>Нэр</th>
                  <th style="width: 8%">Цаг</th>
                  <th
                    style="width: 200px"
                    v-for="(day, dindex) in days"
                    :key="'calendar-' + day.dayIndex + dindex"
                    class="text-center font-weight-bold"
                  >
                    {{ day.name }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(xeelj, lindex) in availableTimes"
                  :key="xeelj.id + lindex"
                >
                  <td
                    style="
                      border-right: 0px !important;
                      border-left: 1px solid #bbb;
                      width: 1%;
                    "
                  >
                    {{ lindex + 1 }}
                  </td>
                  <td>
                    {{ xeelj.name }}
                  </td>
                  <td>
                    {{ xeelj.startTime }}.{{ xeelj.startMin }} -
                    {{ xeelj.endTime }}.{{ xeelj.endMin }}
                  </td>
                  <template v-for="(day, dindex) in days">
                    <!-- <td
                      :key="'cell'+day.name"
                      class="text-center borderCell"
                      @click="_clickedCell(dep, lesson)"
                      v-if="_isContained(dep, lesson,day)"
                      style="background: #f5f591; cursor: pointer"
                      :title="dep.name"
                    >
                      <span>+</span>
                    </td> -->
                    <td
                      :key="'cell-' + day.dayIndex + xeelj.index + dindex"
                      @click="_clickedCell(day, xeelj)"
                      :title="''"
                      style="cursor: pointer"
                      class="px-1 mt-0"
                    >
                      <v-chip
                        class="cal px-2"
                        style="
                          width: 100%;
                          font-size: 10pt;
                          align-items: start !important;
                          border-radius: 4px;
                          cursor: pointer;
                        "
                        v-if="_getLesson(day, xeelj)"
                        color="#00BCD4"
                        dark
                        >{{ _getLesson(day, xeelj).name }}
                      </v-chip>
                      <!-- {{day.dayIndex}}-{{xeelj.index}} -->
                    </td>
                  </template>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card>
    </v-container>

    <v-dialog v-model="selectLessonDialog" max-width="400">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          class="pt-0 text-h6 text-typo justify-center"
          v-if="lessons"
          >Зааx xичээлээ сонгон уу</v-card-title
        >
        <v-card-actions>
          <v-select
            v-if="lessons"
            return-object
            v-model="selectedLesson"
            :items="lessons"
            item-text="name"
            item-value="lessonId"
            label="Xичээл сонгоx"
          ></v-select>
        </v-card-actions>
        <v-card-actions>
          <v-select :disabled="selectedLesson?true:false"
            v-if="
              selectedLesson &&
              selectedLesson.classGroups &&
              selectedClassGroup == null
            "
            return-object
            v-model="selectedLesson.classGroup"
            :items="selectedLesson.classGroups"
            item-text="classGroupFullName"
            item-value="classGroupRef"
            label="Ангиа сонгоx"
          ></v-select>
        </v-card-actions>
        <v-card-actions>
          <v-btn
            @click="_deleteLesson"
            class="btn-danger bg-gradient-danger"
            v-if="selectedLesson"
            >Устгаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn @click="_saveLesson" class="btn-primary bg-gradient-primary"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteConfirmDialog" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Та xичээлээ устгаxыг xүсэж байна уу?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="deleteConfirmDialog = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls
              bg-gradient-light
              py-3
              px-6
            "
            >Үгүй</v-btn
          >

          <v-btn
            @click="_deleteConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-danger
              bg-gradient-danger
              py-3
              px-6
            "
            >Тийм</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  data() {
    return {
      selectedClassGroup: null,
      departmentIndex: "",
      school: null,
      departments: null,
      lessons: null,
      selectedLesson: null,
      selectLessonDialog: false,
      deleteConfirmDialog: false,
      days: [
        { name: "Даваа", dayIndex: 1 },
        { name: "Мягмар", dayIndex: 2 },
        { name: "Лхавга", dayIndex: 3 },
        { name: "Пүрэв", dayIndex: 4 },
        { name: "Баасан", dayIndex: 5 },
        { name: "Бямба", dayIndex: 6 },
        { name: "Ням", dayIndex: 7 },
      ],
      availableTimes: null,
      selectedXeelj: null,
      selectedDay: null,
    };
  },
  watch: {
    selectedClassGroup(val) {
      if (val.calendarData == null) {
        val.ref
          .collection(
            "calendar-" +
              this.userData.school.currentYear +
              "-" +
              this.selectedClassGroup.fullName
          )
          .onSnapshot((docs) => {
            val.calendarData = [];
            docs.forEach((doc) => {
              let cal = doc.data();
              cal.ref = doc.ref;
              val.calendarData.push(cal);
            });
          });
      }
    },
  },
  methods: {
    _calendarConfirm() {
      this.selectedClassGroup.calendarConfirmed =
        !this.selectedClassGroup.calendarConfirmed;
      this.selectedClassGroup.ref.update({
        calendarConfirmed: this.selectedClassGroup.calendarConfirmed,
      });
    },
    async _clickedCell(day, xeelj) {
      this.selectedDay = day;
      this.selectedXeelj = xeelj;
      console.log(this.selectedXeelj);

      var lesson = null;
      if (this.selectedClassGroup && this.selectedClassGroup.calendarData) {
        for (var ca of this.selectedClassGroup.calendarData) {
          if (ca.dayIndex == day.dayIndex && ca.xeelj == xeelj.index) {
            lesson = ca;
            break;
          }
        }
      }
      this.selectedLesson = lesson;
      console.log(this.selectedLesson);
      this.selectLessonDialog = true;
    },
    _getLesson(day, xeelj) {
      var kk = null;
      if (this.selectedClassGroup && this.selectedClassGroup.calendarData) {
        for (var ca of this.selectedClassGroup.calendarData) {
          if (ca.dayIndex == day.dayIndex && ca.xeelj == xeelj.index) {
            
            kk = ca;
            break;
          }
        }
      }
      return kk;
      // return "BB-" + day.dayIndex + "/" + xeelj.index;
    },
    async _saveLesson() {
      console.log(this.selectedLesson);
      console.log(this.selectedClassGroup);
      if (this.selectedClassGroup) {
        this.selectedClassGroup.ref
          .collection(
            "calendar-" +
              this.userData.school.currentYear +
              "-" +
              this.selectedClassGroup.fullName
          )
          .add({
            name: this.selectedLesson.name,
            lessonType: this.selectedLesson.lessonType,
            lessonTypeName: this.selectedLesson.lessonTypeName,
            dayIndex: this.selectedDay.dayIndex,
            xeelj: this.selectedXeelj.index,
            lessonId: this.selectedLesson.lessonId,
            lessonRef: this.selectedLesson.ref,
            lessonCategoryRef: this.selectedLesson.categoryRef,
            teacherRef: this.selectedLesson.teacherRef,
            teacherFirstName: this.selectedLesson.teacherFirstName,
            teacherLastName: this.selectedLesson.teacherLastName
              ? this.selectedLesson.teacherLastName
              : null,
            year: this.selectedLesson.year,
          })
          .then((calendarClassGroup) => {
            this.selectedLesson.teacherRef
              .collection("teacherCalendar-" + this.userData.school.currentYear)
              .add({
                classGroupRef: this.selectedClassGroup.ref,
                classGroupName: this.selectedClassGroup.name,
                classGroupFullName: this.selectedClassGroup.fullName,
                cellNumber: this.selectedDay.dayIndex,
                dayIndex: this.selectedDay.dayIndex,
                // roomNumber: this.selectedRoom,
                departmentName: this.selectedClassGroup.dep.name,
                departmentIndex: this.selectedClassGroup.dep.index,
                // classGroupFullName: this.selectedClassGroup.fullName,
                lessonCategoryRef: this.selectedLesson.categoryRef,
                lessonTypeName: this.selectedLesson.lessonTypeName,
                lessonType: this.selectedLesson.lessonType,
                lessonName: this.selectedLesson.name,
                xeeljNumber: this.selectedXeelj.index,
                year: this.selectedLesson.year,
                calendarClassGroupRef: calendarClassGroup,
              })
              .then((calendarTeacherRef) => {
                console.log(calendarTeacherRef.path)
                console.log(calendarClassGroup.ref.path)
                calendarClassGroup
                  .update({
                    calendarTeacherRef: calendarTeacherRef,
                  })
                  .then(() => {
                    this.selectLessonDialog = false;
                  });
              });
          });
      }  
      this.selectLessonDialog = false;
    },
    _deleteLesson() {
      this.deleteConfirmDialog = true;
    },
    _deleteConfirm() {
      console.log(this.selectedLesson)
      this.selectedLesson.calendarTeacherRef.delete().then(() => {
        this.selectedLesson.ref.delete().then(() => {
          this.deleteConfirmDialog = false;
          this.selectLessonDialog = false;
        });
      });
    },
    _isContained(dep, lesson) {
      if (lesson && lesson.departments) {
        for (const dep2 of lesson.departments) {
          if (dep.id == dep2.id) {
            return true;
          }
        }
      }
      return false;
    },

    // _clickedCell(dep, lesson) {
    //   var added = this._isContained(dep, lesson);
    //   if (added) {
    //     lesson.ref
    //       .collection("departments-" + this.userData.school.currentYear)
    //       .doc(dep.id)
    //       .delete();
    //   } else {
    //     lesson.ref
    //       .collection("departments-" + this.userData.school.currentYear)
    //       .doc(dep.id)
    //       .set(
    //         { name: dep.name, index: dep.index, ref: dep.ref },
    //         { merge: true }
    //       );
    //   }
    // },

    async _setupp() {
      if (this.userData.school && this.userData.school.currentYear) {
        if (this.userData.school && this.userData.school.currentYear) {
          if (this.userData["classGroup-" + this.userData.school.currentYear])
            var dep = await this.userData[
              "classGroup-" + this.userData.school.currentYear
            ].parent.parent.get();
          if (dep && dep.exists) {
            this.departmentIndex = dep.data().index;
          }

          this._getDepartments2(this.userData.school.currentYear);
          this.userData.school.ref.collection("xeelj").onSnapshot((docs) => {
            this.availableTimes = [];
            var counter = 1;
            docs.forEach((doc) => {
              let eelj = doc.data();
              eelj.id = doc.id;
              eelj.ref = doc.ref;
              eelj.index = counter;
              counter++;
              this.availableTimes.push(eelj);
            });
          });
        }
      }
      var query = this.userData.school.ref.collection("lessons");

      // if(this.userData['classGroup-'+this.userData.school.currentYear])
      //   query = query.where("classGroupRefs", "array-contains", this.userData['classGroup-'+this.userData.school.currentYear])

      if (this.userData["classGroup-" + this.userData.school.currentYear]) {
        // this.userData["classGroup-" + this.userData.school.currentYear]
        //   .collection(
        //     "calendar-" +
        //       this.userData.school.currentYear +
        //       "-" +
        //       this.departmentIndex
        //   )
        //   .onSnapshot((docs) => {
        //     this.calendarData = [];
        //     docs.forEach((doc) => {
        //       let cal = doc.data();
        //       cal.ref = doc.ref;
        //       // this.calendarData[cal.day][cal.xeelj] =cal
        //       this.calendarData.push(cal);
        //     });
        //   });
      }
      query.onSnapshot((docs) => {
        this.lessons = [];
        docs.forEach((doc) => {
          let lesson = doc.data();
          lesson.ref = doc.ref;
          lesson.lessonId = doc.id;
          // console.log(lesson.ref.path);
          this.lessons.push(lesson);
        });
      });
      //this._getDepartments2(this.userData.school.currentYear);
    },
    _getDepartments2(startYear) {
      if (this.zzschool != null) {
        fb.db
          .collection(this.zzschool + "/departments")
          .where("startYear", "==", startYear)
          .orderBy("index", "desc")
          .onSnapshot((querySnapshot) => {
            this.departments = [];
            querySnapshot.forEach(async (doc) => {
              this.countOfPrograms = 0;
              let dep = doc.data();
              dep.id = doc.id;
              dep.ref = doc.ref;
              dep.classGroups = null;

              // this.departments.push(dep);
              await dep.ref
                .collection("programs")
                .orderBy("name", "asc")
                .onSnapshot((querySnapshot) => {
                  dep.classGroups = [];
                  dep.numberOfStudents = 0;
                  querySnapshot.forEach(async (doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    let prog = doc.data();
                    prog.id = doc.id;
                    prog.ref = doc.ref;
                    prog.dep = dep;
                    prog.name2 = dep.name + prog.name.toUpperCase();
                    prog.classGroupFullName =
                      dep.name + prog.name.toUpperCase();
                    if (prog.calendarConfirmed) {
                      prog.name2 = prog.name2 + "*";
                    }
                    prog.calendarData = null;
                    if (this.selectedClassGroup == null)
                      this.selectedClassGroup = prog;
                    // if (this.selectedDepAndClass == null) {
                    //   this.selectedDepAndClass = prog;
                    //   prog.ref
                    //     .collection(
                    //       "huvaariData-" + this.userData.school.currentYear
                    //     )
                    //     .onSnapshot((docs) => {
                    //       this.events = [];
                    //       docs.forEach((doc) => {
                    //         let event = doc.data();
                    //         event.ref = doc.ref;
                    //         event.id = doc.id;
                    //         this.events.push(event);
                    //       });
                    //     });
                    // }
                    this.departments.push(prog);
                  });
                });
            });
          });
      }
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
  },

  created() {
    if (this.zzschool != null && this.zzschool != "") this._setupp();
  },
};
</script>

<style>
.bborder td {
  border-right: 1px solid #bbb !important;
}
.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}
.bborder .borderCell:hover {
  background-color: red;
}
.v-chip .v-chip__content {
  align-items: start !important;
  display: inline-flex;
  height: 100%;
  max-width: 100%;
}
</style>